<template>
  <modal @update:show="onClose" :show="show" class="price-list-modal">
    <h6 slot="header" class="modal-title">{{ $t('message.pricelist') }}</h6>

      <h4>{{$t('message.material')}}</h4>
      <Materials :materials="materials" @result-changed="onResultChanged"></Materials>


      <h4>{{$t('message.construction')}}</h4>
      <Materials :materials="constructions" @result-changed="onResultChanged"></Materials>
    
    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="show = false">{{ $t('message.close') }}</base-button>
    </template>
  </modal>
</template>
<script>
import Materials from "./Materials"

export default {
  name: "PriceDetail",
  components: {Materials},
  computed: {
    favoriteProjects() {},
    
    materials() {
      return this.position.materials.filter(mat => mat.type=='MAT')
    },
    constructions() {
      return this.position.materials.filter(mat => mat.type=='KON')
    }
  },
  props: {
    position: Object,
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mats1: [],
      mats2: []
    };
  },
  methods: {
    onClose(state) {
      this.$emit("update:show", state);
    }
  },
  mounted() {}
};
</script>
<style></style>
