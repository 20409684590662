
<template>
  <div class="row no-gutters bhb-grid">
    <div class="bhb-item" :class="colClass" v-for="(item, index) in wrappedItems" :key="index" >
      <bhb-grid-item :item="item" :index="index" :imageSrc="imageUrl(item, 'annotation')" v-model="item.isChecked" @input="handleSelectionChange"></bhb-grid-item>
    </div>
  </div>

</template>
<script>
  // Charts
  import BhbGridItem from './GridItem'
  import { mapMutations } from "vuex";

  export default {
    name: 'GridView',
    components: {
      BhbGridItem
    },
    props: {
      items: {
        type: Array,
        required: true
      },
      project: {
        type: Object,
        required: true
      },
      docs: {
        type: Object,
        required: true
      },
      columns: {
        type: Number,
        default: 2
      }
    },
    computed: {
      colClass () {
        const width = this.items.length < this.columns ? Math.floor(12/this.items.length) : 12 / this.columns;
        return `col-xl-${width} col-sm-6 col-xs-12`
      },
      wrappedItems () {
        return [...this.items].map(item => {
          this.$set(item, 'isChecked', false)
          return item
        })
      },
      selectedItems () {
        return this.wrappedItems.filter(i => i.isChecked)
      }
    },
    data() {
      return {
      }
    },
    // watch: {
    //   selectedItems () {
    //     this.
    //   }
    // },
    methods: {
      ...mapMutations([
        'UPDATE_SELECTED_IM_POSITIONS'
      ]),
      imageUrl(item, path, index=0) {
        if (item.documents) {
          const found = item.documents
            .filter(doc => doc.path === path)
            .reverse()
          if (found.length>0) {
            return found[0].url
          }
        }
        return '/app/img/empty.png';
      },
      handleSelectionChange () {
        const selectedIds = this.selectedItems.map(p => p.id)
        this.UPDATE_SELECTED_IM_POSITIONS(selectedIds)
      }
    }
  };
</script>
<style scoped>
.col-md-6 {
  flex-grow: 1;
  transition: all 400ms ease;
}

.col-md-4 {
  width: 0;
  flex-shrink: 1;
  transition: all 400ms ease;
}
</style>
