<template>
  <card style="margin-top: 75px">
    <template slot="header">
      <div class="p-0 d-flex justify-content-between">
        <h3>{{ $t("message.informations") }}</h3>
        <div class="p-0 d-flex align-items-center">
          <div class="mr-3 text-bold text-nowrap">Montagezeit (in h)</div>
          <el-input @input="onManpowerChanged" placeholder="Stunden" v-model="position.manpower" />
        </div>
      </div>
    </template>

    <h4>{{ $t("message.material") }}</h4>
    <Materials
      :materials="materials"
      @result-changed="onResultChanged"
    ></Materials>

    <h4>{{ $t("message.construction") }}</h4>
    <Materials
      :materials="constructions"
      @result-changed="onResultChanged"
    ></Materials>

    <div class="sep"></div>

    <div class="row">
      <div class="col-xl-2 col-lg-3 col-md-4 text-bold">
        {{ $t("message.assembly") }}
      </div>
      <div class="col-xl-2 col-lg-9 col-md-8">
        {{
          position.assembly_info
            ? currentLocale === "de"
              ? position.assembly_info.name
              : position.assembly_info.name_en
            : ""
        }}
      </div>
      <div class="col-xl-2 col-lg-3 col-md-4 text-bold">
        {{ $t("message.remark") }}
      </div>
      <div class="col-xl-2 col-lg-9 col-md-8">{{ position.bhb_note }}</div>
      <div class="col-xl-2 col-lg-3 col-md-4 text-bold">
        {{ $t("material_additional.pt_number") }}
      </div>
      <div class="col-xl-2 col-lg-9 col-md-8">{{ position.pt_number }}</div>
    </div>
    <!-- <div class="row m-1 mt-3">
      Moved to PositionHeader
    </div> -->
  </card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Materials from "./Materials";

export default {
  name: "info-card",
  props: {
    position: Object,
  },
  components: { Materials },
  computed: {
    ...mapGetters(["currentUserRights", "currentLocale"]),
    materials() {
      return this.position.materials.filter((mat) => mat.type == "MAT");
    },
    constructions() {
      return this.position.materials.filter((mat) => mat.type == "KON");
    },
  },
  methods: {
    ...mapActions([]),
    onResultChanged(mat) {
      const action = "updateMaterial";
      this.$store.dispatch(action, {
        id: mat.id,
        data: {
          rueck_result_customer: mat.rueck_result_customer,
        },
      });
    },
    onManpowerChanged(val) {
      this.$store.dispatch("updatePosition", {
        id: this.position.id,
        data: {
          manpower: val,
        },
      });
    }
  },
};
</script>

<style scoped>
.sep {
  margin-top: 20px;
}
</style>
