var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{staticClass:"table-responsive table-flush",attrs:{"data":_vm.materials,"header-row-class-name":"thead-light","stripe":""}},[_c('el-table-column',{attrs:{"label":_vm.$t('message.masse'),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("number")(row.width))+" x "+_vm._s(_vm._f("number")(row.height))+" mm ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.article'),"width":"230"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("material")(row,_vm.currentLocale))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('data_input.category'),"width":"230"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("category")(row,_vm.currentLocale))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.amount'),"prop":"quantity"}}),(_vm.isCustomerAdr && _vm.currentUserRights.includes('BHB - Vollbild_Ansicht mit Preis'))?_c('el-table-column',{attrs:{"label":_vm.$t('message.unitPrice'),"align":"right","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.price_unit))+" ")]}}],null,false,3303427061)}):_vm._e(),(_vm.isCustomerAdr && _vm.currentUserRights.includes('BHB - Vollbild_Ansicht mit Preis'))?_c('el-table-column',{attrs:{"label":_vm.$t('message.price_total'),"align":"right","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("currency")(row.price_total))+" ")]}}],null,false,925366033)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('message.weight'),"prop":"price_unit","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("number")(row.weight))+" kg ")]}}])}),_c('el-table-column',{attrs:{"min-width":"180","label":_vm.$t('message.fire_protection_class'),"prop":"mat.brandschutz"}}),_c('el-table-column',{attrs:{"label":_vm.$t('message.backNote'),"width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-select',{attrs:{"placeholder":"Resultat","disabled":!_vm.currentUserRights.includes('im.rueck_result_customer.canchange') || _vm.project.state === 'ARCHIVED'},on:{"change":function($event){return _vm.onResultChanged(row)}},model:{value:(row.rueck_result_customer),callback:function ($$v) {_vm.$set(row, "rueck_result_customer", $$v)},expression:"row.rueck_result_customer"}},_vm._l((_vm.results),function(option){return _c('el-option',{key:option,attrs:{"label":option,"value":option}})}),1)],1)],1),(row.user_rueck_ref)?_c('el-row',[_c('el-col',{attrs:{"span":24}},[_vm._v(" "+_vm._s(row.user_rueck_ref.name)+", "+_vm._s(_vm._f("datetime")(row.date_rueck))+" ")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }