
<template>
  <a href="#" class="list-group-item list-group-item-action flex-column align-items-start py-4 px-4">
    <div class="d-flex w-100 ">
      <img :src="item.image" style="height:100px;" class="rounded"/>
      <div class="">
        <div class="w-100 ml-1">
          <h5 class="ml-1">{{item.area}}</h5>
          <span class="h2 font-weight-bold mb-0">{{item.position}}</span>

        </div>
      </div>
      <small>{{item.date}}</small>
    </div>
    <h4 class="mt-3 mb-1">{{item.subject}} <i class="fas fa-paperclip"></i></h4>
    <p class="text-sm mb-0">{{ item.text }}</p>
  </a>

</template>
<script>
  // Charts

  export default {
    name: 'ListItem',
    props: {
      item: {
        type: Object,
        required: true
      },
      index: {
        type: Number
      }
    },

    data() {
      return {
      }
    },
    methods: {
      copySelection () {

      },
      deleteSelection () {

      }
    },
    mounted() {
 
    }
  };
</script>
<style scoped>
.col-md-6 {
  flex-grow: 1;
  transition: all 400ms ease;
}

.col-md-4 {
  width: 0; 
  flex-shrink: 1;
  transition: all 400ms ease;
}
</style>
