
<template>
   <modal :show.sync="show" v-loading="loading">
    <h6 slot="header" class="modal-title">{{$t('message.titleCoverPage')}} {{currentAddrName}}</h6>
    <div class="row">
        <div class="col-md-6">
            <base-input :label="$t('message.image')" >
                <el-upload
                    class="_avatar-uploader"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :show-file-list="false"
                    :http-request="data => imageFile=data.file"
                    :on-change="(res,files) => image = getImage(files)">
                    <img v-if="image" :src="image.url" class="avatar-preview">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </base-input>
          <base-button v-if="image" size="sm" type="primary" @click="deleteCurrent(null, image)">
            <i class="fa fa-trash fa-big m-1"></i>
          </base-button>
        </div>
        <div class="col-md-6">
            <base-input :label="$t('message.customerLogo')" >
                <el-upload
                    class="_avatar-uploader"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :show-file-list="false"
                    :http-request="data => logoFile=data.file"
                    :on-change="(res,files) => logo = getImage(files)">
                    <img v-if="logo" :src="logo.url" class="avatar-preview">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </base-input>
          <base-button v-if="logo" size="sm" type="primary" @click="deleteCurrent(logo, null)">
            <i class="fa fa-trash fa-big m-1"></i>
          </base-button>
        </div>
    </div>
    <template slot="footer">
        <base-button type="primary" @click="save">{{$t('message.save')}}</base-button>
        <base-button type="neutral" @click="$emit('close')">{{$t('message.cancel')}}</base-button>
    </template>
  </modal>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'FrontPage',
    props: {
      show: {
        type: Boolean,
        required: true
      },
      project: {
        type: Object
      }
    },
    computed: {
      ...mapGetters([
        'currentProjectAddress',
        'projectAddresses',
      ]),
      colClass () {
        const width = this.items.length < this.columns ? Math.floor(12/this.items.length) : 12 / this.columns;
        return `col-md-${width}`
      },
      currentAddrName() {
        if (this.currentProjectAddress!=0) {
          const addr = this.projectAddresses.find(a => a.address.id == this.currentProjectAddress)
          if (addr!=null) {
            return addr.address.name
          }
        }
        return null
      },
    },
    watch: {
      async show() {
        this.image = null
        this.logo = null
        if (this.show) {
          const name = this.currentAddrName
          this.loading = true
          if (name==null) {
            const docs = this.project.documents || []
            this.logo = docs.find(doc => doc.path === 'bhb-logo')
            this.image = docs.find(doc => doc.path === 'bhb-image')
          } else {
            const addr = this.projectAddresses.find(a => a.address.id == this.currentProjectAddress)
            const docs = addr.documents || []
            this.logo = docs.find(doc => doc.path === 'bhb-logo')
            this.image = docs.find(doc => doc.path === 'bhb-image')
          }
          this.loading = false
        }
      }
    },
    data() {
      return {
        image: null,
        logo: null,
        imageFile: null,
        logoFile: null,
        loading: false,
      }
    },
    methods: {
      async deleteCurrent(logo, image) {          
          if (logo) {
            let existingLogo = this.project.documents.find(doc => doc.path === 'bhb-logo')
            if (existingLogo) {
              await this.$store.dispatch('deleteDocApi', {docs: [logo]})
            }
            this.project.documents = this.project.documents.filter(d => d.id != logo.id)
            this.logo = null;
          }
          if (image) {
            let existingImage = this.project.documents.find(doc => doc.path === 'bhb-image')
            if (existingImage) {
              await this.$store.dispatch('deleteDocApi', {docs: [image]})
            }
            this.project.documents = this.project.documents.filter(d => d.id != image.id)
            this.image = null;
          }
      },
      async deleteAll(docs) {
        if (docs) {
          docs.forEach(doc => this.$store.dispatch('deleteDoc', doc))
        }
      },
      async save () {
        this.loading = true
        try {
          const files = {}
          if (this.logoFile) files['bhb-logo'] = [this.logoFile]
          if (this.imageFile) files['bhb-image'] = [this.imageFile]
          if (this.currentProjectAddress==0) {
            await this.$store.dispatch('uploadDocument', { type:'project', id: this.project.id, files: files, api: true })
          } else {
            const addr = this.projectAddresses.find(a => a.address.id == this.currentProjectAddress)
            await this.$store.dispatch('uploadDocument', { type:'project_address', id: addr.id, files: files, api: true })
          }
        } catch(error) {
          this.$notify({
            verticalAlign: "top",
            horizontalAlign: "right",
            message: error.body ? error.body.message : error,
            type: "error"
          });

        }
        this.$emit("close");
        this.loading = false
      },
      getImage(files) {
        const upload = files.slice(-1)[0];
        return {
          url: URL.createObjectURL(upload.raw)
        }
      },
    },
    mounted() {

    }
  };
</script>
<style>
  ._avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ._avatar-uploader .el-upload:hover {
    border-color: #505050;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar-preview {
    width: auto;
    max-width: 400px;
    height: 175px;
    display: block;
  }
  .fa-big {
    font-size: 20px;
  }
</style>
