<template>
  <modal @update:show="onShowChange" :show="show" class="price-list-modal"
    :bodyClasses="'dropdown-overflow-correction'">
    <h6 slot="header" class="modal-title">{{ $t("message.mounted") }}</h6>
    <div class="d-flex justify-content-center">
      <base-button class="" v-if="!isOkSelected" size="lg" type="primary" @click="selectOk(true)">
        {{ $t("message.ok") }}
      </base-button>
      <base-button v-if="!isOkSelected" size="lg" type="light" @click="selectOk(false)">
        {{ $t("message.not_ok") }}
      </base-button>
    </div>

    <apa-comments v-if="isOkSelected && !isOk"
      :commentTextAddon="'Die Position ' + position.key + ' wurde mit „Nicht OK“ gekennzeichnet mit folgendem Kommentar '"
      :createAlsoAsDebriefing="!isOk" 
      hideComments 
      placeholder="Montiernotiz?" 
      @created="onCreated" 
      :fixtures="{
        project_id: position.project_id,
        position_id: position.id,
      }" />
    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onClose">{{
        $t("message.cancel")
      }}</base-button>
    </template>
  </modal>
</template>
<script>
import { mapGetters } from "vuex";

import ApaComments from "@/components/ApaComments/ApaComments";

export default {
  name: "MountedModal",
  components: {
    ApaComments,
  },
  data() {
    return {
      isOkSelected: false,
      isOk: false,
    }
  },
  props: {
    position: {
      type: Object,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.onShowChange(false);
    },
    onCreated() {
      this.$emit("created", { isOk: this.isOk });
    },
    selectOk(value) {
      this.isOk = value;
      if (this.isOk) {
        this.$emit("created", { isOk: this.isOk });
      }
      this.isOkSelected = true;
    },
    onShowChange(state) {
      this.isOkSelected = false;
      this.isOk = false;
      this.$emit("update:show", state);
    },
  },
};
</script>
<style>
.dropdown-overflow-correction {
  overflow-y: inherit !important;
}
</style>
