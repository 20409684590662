<template>
  <a @click="onItemClicked(item)"
    class="list-group-item list-group-item-action flex-column align-items-start py-4 px-4">
    <div class="d-flex w-100 justify-content-between">
      <div>
        <div class="d-flex w-100 align-items-center">
          <img v-if="item.image" :src="item.image" alt="Image placeholder" class="avatar avatar-xs mr-2">
          <h5 class="mb-1">{{ item.username }}</h5>
        </div>
      </div>
      <small>{{ item.date }}</small>
    </div>
    <h4 class="mt-3 mb-1">{{ item.title }}</h4>
    <p class="text-sm mb-0">{{ item.text }}</p>
  </a>
</template>
<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      items: [
        {
          title: 'Call with Dave',
          description: '10:30 AM',
          done: true,
          checkType: 'success'
        },
        {
          title: 'Lunch meeting',
          description: '10:30 AM',
          done: false,
          checkType: 'warning'
        },
        {
          title: 'Argon Dashboard Launch',
          description: '10:30 AM',
          done: false,
          checkType: 'info'
        },
        {
          title: 'Winter Hackaton',
          description: '10:30 AM',
          done: true,
          checkType: 'danger'
        }
      ]
    };
  },
  methods: {
    onItemClicked(item) {
      this.$emit('item-clicked', item)
    }
  }
};
</script>
