<template>
  <img v-if="docs.length>0 && docs[index].mimetype.startsWith('image')" :src="docs[index].url" style="width: 100%"/>
</template>
<script>
export default {
  props: ['type', 'id', 'name', 'multiple'],
  data() {
    return {
      docs: [],
      index: 0,
    }
  },
  mounted () {
    if (this.id) {
      this.$store.dispatch('getDocs', {type: this.type, id: this.id})
        .then(items => this.docs = items.filter(d => d.path==this.name))
    }
  }
};
</script>
