var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card-footer py-4 d-flex justify-content-between align-items-center"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.currentPage,"total":_vm.items.length,"page-count":_vm.pageCount,"page-size":_vm.pageSize,"layout":"prev, pager, next"},on:{"current-change":_vm.onCurrentChange}}),_c('el-select',{staticClass:"select-primary mr-2",attrs:{"filterable":""},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}},[_c('el-option',{key:"10",staticClass:"select-primary ml-1",attrs:{"value":"10","label":"10"}}),_c('el-option',{key:"50",staticClass:"select-primary ml-1",attrs:{"value":"50","label":"50"}}),_c('el-option',{key:"100",staticClass:"select-primary ml-1",attrs:{"value":_vm.listLength,"label":_vm.$t('message.page_size_all', { allCount: _vm.listLength })}})],1)],1),_c('el-table',{ref:"imTable",staticClass:"table-responsive table-flush",staticStyle:{"width":"100%"},attrs:{"data":_vm.paginatedItems,"header-row-class-name":"thead-light","cell-class-name":"clickable","row-class-name":_vm.tableRowClassName,"default-sort":{prop: 'tempsort', order: 'ascending'},"stripe":""},on:{"row-click":_vm.onClick,"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"type":"index"}}),_c('el-table-column',{attrs:{"label":_vm.$t('message.motive'),"prop":"tempsort","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"placement":"right"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[(_vm.hasImageUrl(row, 'designs'))?_c('img',{staticStyle:{"width":"400px"},attrs:{"src":_vm.imageUrl(row, 'designs')}}):_vm._e()]),(_vm.hasImageUrl(row, 'annotation'))?_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.imageUrl(row, 'annotation')}}):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"key","label":_vm.$t('message.id'),"width":"120"}}),_c('el-table-column',{attrs:{"prop":"address.address.name","label":_vm.$t('message.einsatzort'),"width":"180"}}),_c('el-table-column',{attrs:{"prop":"area.name","label":_vm.$t('message.area'),"width":"180"}}),_c('el-table-column',{attrs:{"prop":"color.name","label":_vm.$t('message.color'),"width":"180"}}),_c('el-table-column',{attrs:{"prop":"bhb_position.name","sortable":"","sort-method":_vm.sortPosition,"label":_vm.$t('message.position'),"width":"220"}}),_c('el-table-column',{attrs:{"label":_vm.$t('message.width'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!!row.materials)?_vm._l((row.materials.filter(function (m) { return m.type === 'MAT'; })),function(mat){return _c('div',[_vm._v(_vm._s(_vm._f("number")(mat.width)))])}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.height'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!!row.materials)?_vm._l((row.materials.filter(function (m) { return m.type === 'MAT'; })),function(mat){return (!!row.materials)?_c('div',[_vm._v(_vm._s(_vm._f("number")(mat.height)))]):_vm._e()}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.amount'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!!row.materials)?_c('div',[_vm._v(_vm._s(row.materials[0].quantity))]):_vm._e()]}}])}),(_vm.currentUserRights.includes('BHB - Vollbild_Ansicht mit Preis'))?_c('el-table-column',{attrs:{"prop":"","label":_vm.$t('message.price_total'),"width":"130","align":"right","header-align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.isCustomerAdr(_vm.currentUser, _vm.project, row.address))?_c('span',[(row.price_percent>0)?_c('span',[_vm._v(_vm._s(row.price_percent)+" %")]):_c('span',[_vm._v(_vm._s(_vm._f("currency")(row.price_total)))])]):_vm._e()]}}],null,false,4152136835)}):_vm._e(),_c('el-table-column',{attrs:{"sortable":"","sort-method":_vm.sortMaterial,"label":"Material","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("firstmaterial")(row,'MAT', _vm.currentLocale))+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","header-align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [((_vm.currentUserRights.includes('bhb.export.priority_visible') && row.priority_internal === 1 && row.priority)
                        || (row.priority_internal === 0 && row.priority))?[(row.priority)?_c('el-tooltip',{attrs:{"placement":"right"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(row.priority_reason)+" ")]),_c('i',{staticClass:"fas fa-bolt active-star mr-2"})]):_vm._e()]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"status","sortable":"","sort-method":_vm.sortStatus,"label":_vm.$t('message.state'),"align":"center","header-align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [_c('bhb-status-badge',{attrs:{"state":row.state_bhb}})]}}])}),_c('el-table-column',{attrs:{"prop":"release","sortable":"","sort-method":_vm.sortEconomic,"label":_vm.$t('message.economic_release'),"header-align":"center","align":"center","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [_c('base-checkbox',{attrs:{"checked":row.rel_business == 1,"disabled":""}})]}}])}),(_vm.currentUserRights.includes('bhb.status.can-modify-handlung-pl'))?_c('el-table-column',{attrs:{"prop":"release","sortable":"","sort-method":_vm.sortPL,"label":_vm.$t('message.actionPL'),"header-align":"center","align":"center","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [_c('base-checkbox',{attrs:{"checked":row.handlung_pl === 1,"disabled":""}})]}}],null,false,3241511517)}):_vm._e(),(_vm.currentUserRights.includes('bhb.status.can-modify-dv-erstellt'))?_c('el-table-column',{attrs:{"prop":"release","sortable":"","sort-method":_vm.sortDV,"label":_vm.$t('message.show_dv_erstellt'),"header-align":"center","align":"center","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [_c('base-checkbox',{attrs:{"checked":row.dv_erstellt == 1,"disabled":""}})]}}],null,false,3789064732)}):_vm._e()],1),_c('div',{staticClass:"card-footer py-4 d-flex justify-content-between align-items-center"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.currentPage,"total":_vm.items.length,"page-count":_vm.pageCount,"page-size":_vm.pageSize,"layout":"prev, pager, next"},on:{"current-change":_vm.onCurrentChange}}),_c('el-select',{staticClass:"select-primary mr-2",attrs:{"filterable":""},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}},[_c('el-option',{key:"10",staticClass:"select-primary ml-1",attrs:{"value":"10","label":"10"}}),_c('el-option',{key:"50",staticClass:"select-primary ml-1",attrs:{"value":"50","label":"50"}}),_c('el-option',{key:"100",staticClass:"select-primary ml-1",attrs:{"value":_vm.listLength,"label":_vm.$t('message.page_size_all', { allCount: _vm.listLength })}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }