<template>
  <card bodyClasses="d-flex  p-0 image-row tablet-stacked" v-loading="isLoading">
    <div class="col-lg-12 col-xl-6 p-0">
      <!-- <image-editor
        alt="Image placeholder"
        class="img-fluid rounded img-center"
        :value="annotations"
        :src="docs[0].url"
       @input="annotationChanged"
        v-if="docs.length > 0"
      /> -->

      <image-editor
        ref="imageEditor"
        @input="annotationChanged"
        :value="annotations"
        @delete="annotationDeleted"/>
    </div>

    <div class="col-lg-12 col-xl-6 p-0 bg-gray-900 preview-image d-flex justify-content-center flex-column">
      <!-- <div class="d-flex align-items-center p-3 top-bar  align-self-start">
        <h3 class="text-white">{{position.key}}</h3>
      </div> -->
      <document-upload type="position" :documents="position.documents" name="designs" multiple=false @changed="onDesignsChanged" @removed="onDesignRemoved" placeholder="Bitte hochladen" :dark-bg="true"></document-upload>

      <!-- <el-carousel arrow="always" class="layout-carousel" :autoplay="false">
        <el-carousel-item v-for="layout in layouts" :key="layout.id">
          <img :src="layout.url" />
        </el-carousel-item>
      </el-carousel> -->
    </div>
  </card>
</template>

<script>
import DocumentUpload from '@/components/DocumentUpload'
import ImageEditor from "@/components/ImageEditor/ImageEditor";
import { dataURItoBlob, resizeImage } from '@/util/data'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "images-card",
  components: {
    ImageEditor,
    DocumentUpload
  },
  props: {
    position: Object
  },
  data() {
    return {
      isLoading: false,
      hasMotherProject: false
    };
  },
  computed: {
    annotations() {
      if (this.position) {
        if (!this.position.bhb_annotation) return this.position.bhb_annotation
        const annotation = JSON.parse(this.position.bhb_annotation)
        if (!annotation || !this.position.documents) return annotation
        const image = this.position.documents.find(doc => doc.path === 'szene')
        annotation.backgroundImage.src = image ? image.url : null
        return JSON.stringify(annotation)
      } else {
        return JSON.stringify({ backgroundImage: {
          src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
        }})
      }
    },
    docUrl () {
      return this.docs.length ? this.docs[0].url : ''
    }
  },
  watch: {
    position(value) {
      this.load();
    }
  },
  methods: {
    ...mapActions([
      'setBhbState',
      'setBhbActionPL'
    ]),
    async load() {
      let projectMatches = await this.$store.dispatch('loadProjectMappingByChild', {
          type: 'project', 
          child_id: this.position.project_id
        })

      if (projectMatches.length) {
        this.hasMotherProject = true;
      }
    },
    annotationChanged(annotation) {
      const self = this;
      let loading = 2;
      this.isLoading = true;

      const annotationBlob = dataURItoBlob(this.$refs.imageEditor.getDataUrl())
      // upload
      // const bhbState = "OFFEN"
      // this.setBhbState({ posId: this.position.id, bhbState });
      if (annotation.type === 'existing') {
        const data = {
          bhb_annotation: JSON.stringify(annotation.json),
          // state_bhb: 'OFFEN'
        };
        this.$store.dispatch('updatePosition', { id: this.position.id, data: data })
          .then(() => resizeImage({ maxSize: 600, file: annotationBlob }))
          .then(imgBlob => {
            const image = new File([imgBlob], 'annotation.png')
            return this.$store.dispatch('uploadDocument', {type:'position', id: self.position.id, files: { annotation: [image] }, api: true})
          })
          .then(stop)
      } else if(annotation.type === 'new') {
        this.$store.dispatch('uploadDocument', {type:'position', id: self.position.id, files: {
          szene: [annotation.image] // Original bild
        }, api: true})
          .then(async () => {
                if (this.hasMotherProject) {
                  let posMap = await this.$store.dispatch('loadProjectMappingByChild', {
                                          type: 'position', 
                                          child_id: self.position.id
                                        });
                  
                  let parentPos = posMap[0].parent_id;
                  
                  this.$store.dispatch('uploadDocument', { type: 'position', id: parentPos, files: { annotation: [image] }, api: true })
                  .then(() => {
                    Vue.http.post('document/projectsync/' + self.position.id, {
                                                                          project_id: this.project.id,
                                                                          data: {type: 'position',
                                                                                path: 'szene',
                                                                                parent_pos_id: parentPos}
                                                                          })
                  })
                }
          })
        .then(() => this.$store.dispatch("getDocs", {
          type: "position",
          id: self.position.id,
          api: true
        }))
        .then(items => {
          return items
            .filter(item => item.path === 'szene')
            .reverse()
        })
        .then(docs => {
          annotation.json.backgroundImage.src = docs[0].url;
          const data = {
            bhb_annotation: JSON.stringify(annotation.json),
            // state_bhb: 'OFFEN'
          }

          return this.$store.dispatch('updatePosition', { id: self.position.id, data })
        })
        .then(() => resizeImage({ maxSize: 600, file: annotationBlob }))
        .then(imgBlob => {
          const image = new File([imgBlob], 'annotation.png')
          return this.$store.dispatch('uploadDocument', {type:'position', id: self.position.id, files: { annotation: [image] }, api: true})
              .then(async () => {
                    if (this.hasMotherProject) {
                      let posMap = await this.$store.dispatch('loadProjectMappingByChild', {
                                              type: 'position', 
                                              child_id: self.position.id
                                            });
                      
                      let parentPos = posMap[0].parent_id;
                      
                      this.$store.dispatch('uploadDocument', { type: 'position', id: parentPos, files: { annotation: [image] }, api: true })
                      .then(() => {
                        Vue.http.post('document/projectsync/' + self.position.id, {
                                                                              project_id: this.project.id,
                                                                              data: {type: 'position',
                                                                                    path: 'annotation',
                                                                                    parent_pos_id: parentPos}
                                                                              })
                      })
                    }
              })
        })
        .then(stop)
        .catch(error => {
          this.$notify({
            verticalAlign: "top",
            horizontalAlign: "right",
            message: "Position konnte nicht gespeichert werden: " + error.body.message,
            type: "error"
          });
        })
      } else {
        return Promise.resolve()
        .then(stop())
      }

/*

      this.isLoading = true;
      let loading = 2;
      const data = {
        bhb_annotation: JSON.stringify(annotation.json)
      };
      this.$store.dispatch('updatePosition', {id: this.position.id, data: data}).then(stop);
      this.$store.dispatch('uploadDocument', {type:'position', id: this.position.id, files: {
          annotated: [annotation.image]
        }}).then(stop);

      const self = this;
      */

      function stop() {
        self.isLoading = false
        self.$notify({
          verticalAlign: "top",
          horizontalAlign: "right",
          message: self.$t('notifications.image.saved'),
          type: "success"
        })

        window.location.reload()

        self.$emit("changed")
      }
    },
    async annotationDeleted() {
      try {
        await this.$confirm(
          `Wollen Sie dieses Szene wirklich löschen?`,
          `Szene löschen`,
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning"
          }
        )
        const docs = this.position.documents.filter(doc => doc.path === 'szene' || doc.path === 'annotation')
        await this.$store.dispatch('deleteDocApi', {docs: docs})
      } catch (error) {
        return;
      }
    },
    onDesignsChanged (docs) {
      const files = {
        'designs[]': docs || []
      }

      const bhbState = docs.length > 0 ?
          (this.position.state_bhb === 'LEER' ? 'LEER' : this.position.state_bhb)
          : 'LEER'

      const actionPL = bhbState === 'LEER' ? true : false
      this.$store.dispatch('uploadDocument', {type:'position', id: this.position.id, files: files, api: true})
        .then(() => this.setBhbState({ posId: this.position.id, bhbState }))
        .then(() => this.setBhbActionPL({ posId: this.position.id, actionPL }))
        .then(async () => {
              if (this.hasMotherProject) {
                let posMap = await this.$store.dispatch('loadProjectMappingByChild', {
                                        type: 'position', 
                                        child_id: this.position.id
                                      });
                
                let parentPos = posMap[0].parent_id;
                
                this.$store.dispatch('uploadDocument', { type: 'position', id: parentPos, files: files, api: true })
                .then(() => {
                  Vue.http.post('document/projectsync/' + this.position.id, {
                                                                        project_id: this.position.project_id,
                                                                        data: {type: 'position',
                                                                              path: 'designs',
                                                                              parent_pos_id: parentPos}
                                                                        })
                })
              }
        })
        .then(() => this.$emit("changed"))

      // this.layouts = docs
    },
    onDesignRemoved (docs) {
      const bhbState = docs.length > 0 || this.item.state_bhb !== 'LEER'  ?
        (this.position.state_bhb === 'LEER' ? 'LEER' : this.position.state_bhb)
        : 'LEER'

        this.setBhbState({ posId: this.position.id, bhbState })
          .then(() => this.$emit("changed"))
    },
    onCanvasChanged () {
      const data = this.$refs.imageEditor.editorInstance.toObject()
      // this.annotationChanged(data)
    },
    onImageChanged (e) {
      if (e.cacheKey !== 'texture0') {
        const data = this.$refs.imageEditor.editorInstance.toDataURL();
        this.annotationChanged(data)
      }
    },
    save() {
      // bg Image
      const canvas = this.$refs.imageEditor.editorInstance._graphics._canvas.backgroundImage.getSrc();

      const result = this.$refs.imageEditor.editorInstance.toObject();
      const backgroundImage = result.backgroundImage.src;
      const img = new Image();

      if (backgroundImage.indexOf('data:') === 0) {
        const blob = b64toBlob(backgroundImage)
      }

      if (backgroundImage.indexOf('blob:') === 0) {
        const r = fetch(canvas)
          .then(r => r.blob())
          .then(r => {

          })
      }

      //current edited image
      const renderedImage = this.$refs.imageEditor.editorInstance.toDataURL()
    }
  },
  mounted() {
    this.load();
  }
};
</script>

<style>

@media (max-width: 1199px) {
    .card-body.image-row.tablet-stacked {
        flex-direction: column;
        min-height: 90vh;
    }
    .card-body.image-row.tablet-stacked > div {
        height: 45vh;
        min-height: 860px;
    }
    .tui-image-editor-container .tui-image-editor-header {
      min-width: 100%;
    }
}  

.uploader {
  height: 100%;
}

.preview-image img {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 90%;
}

.preview-image .top-bar {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.preview-image .button-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}


</style>
