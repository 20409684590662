<template>
  <card>
    <template slot="header">
      <div class="row">
        <h3 class="col-md-8">{{$t('message.archive')}}</h3>
        <div class="col-md-4 text-right">
          <base-button v-if="hidden" v-loading="loading" type="primary" class="right" @click="hidden = false; load()">
            <i class="fas fa-caret-left"></i>
          </base-button>
          <base-button v-if="!hidden" type="primary" @click="hidden = true">
            <i class="fas fa-caret-down"></i>
          </base-button>
        </div>
      </div>
    </template>
    <template v-if="!hidden" >
      <news-item class="clickable" v-for="item in news" :item="item" :key="item.id" ></news-item>
    </template>
  </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NewsItem from '@/views/News/NewsItem'
import Vue from "vue"
const hidden = ['id', 'functions', 'assembly', 'bhb_annotation']

export default {
  name: "archive-card",
  components: {
    NewsItem
  },
  props: {
    position: Object,
  },
  computed: {
    ...mapGetters([
      'project',
    ]),
    news() {
      return this.log
        .filter(log => !['task_fav', 'notification'].includes(log.type))
        .map(log => ({
          id: log.id,
          username: log.user.name,
          date:log.created_at,
          title: this.$t('log.type.'+log.type)+ " " +this.$t('log.operation.'+log.operation),
          text: this.textFor(log),
        }))
    }
  },
  data() {
    return {
      hidden: true,
      loading: false,
      loaded: false,
      log: []
    }
  },
  methods: {
    textFor(log) {
      const result = []
      const content = JSON.parse(log.content)
      Object.keys(content).forEach(key => {
          if (hidden.indexOf(key)==-1 && !key.endsWith('_id')) {
            const value = content[key]  || '-'
            result.push(key + ': ' + value)
          }
      })
      return result.join(', ')
    },
    load() {
      if (this.loaded) return;
      this.loading = true;
      Vue.http.post(`log/query`, {
        or: {
          'content->position_id': this.position.id,
          'and': {
            type: 'position',
            item_id: this.position.id
          }
        },
        with: {
          user: {one: 'users', this: 'user_id'}
        },
        order: {
          created_at: 'desc'
        }
      })
      .then(res => res.json())
      .then(log => {
        this.loading = false;
        this.loaded = true;
        this.log = log
      })
      .catch(error => {
        this.loading = false;
        this.loaded = false;
      })
    }
  },
}
</script>

