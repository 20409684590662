
<template>
  <stats-card :title="item.area ? item.area.name : ''" :sub-title="item.bhb_position ? item.bhb_position.name : ''" class="mb-0" :class="{'disabled-row': item.state_bhb === 'ABGELEHNT'}">
    <template slot="icon" v-if="(currentUserRights.includes('bhb.export.priority_visible') && item.priority_internal === 1 && item.priority)
                                || (item.priority_internal === 0 && item.priority)">
      <el-tooltip class="item" effect="dark" placement="top-start" v-if="item.priority">
        <div slot="content">
          {{ item.priority_reason }}
        </div>
        <i class="fas fa-bolt faa-flash animated animated active-star"></i>
      </el-tooltip>
      <el-badge :value="item.alerts" class="item" v-if="item.alerts">
        <i class="fas fa-bell faa-shake animated"></i>
      </el-badge>
    </template>
    <template slot="footer">
      <div class="cropped-img-container clickable" @click="onClick(item.id)">
        <img :src="item.image" class="rounded"/>
        <div :style="'background-image:url(' + imageSrc + ')' " class="cropped-img" />
      </div>
      <div class="d-flex justify-content-between footer-row">
        <div >
            <b class="clickable" @click="onClick(item.id)">{{item.key}}</b>
        </div>
         <div>
            <bhb-status-badge :state="item.state_bhb"></bhb-status-badge>
        </div>
      </div>
      <div class="footer-row">
          <div>
            <small v-if="isCustomerAdr(currentUser, project, item.address)">
              <b>{{$t('message.price_total')}}: </b>{{item.price_total | currency}}
            </small>
          </div>
          <div>
            <small><b>{{$t('message.material')}}:</b> {{ item | firstmaterial('MAT', currentLocale) }}</small>
         </div>
          <div>
            <small><b>{{$t('message.construction')}}:</b> {{ item | firstmaterial('KON', currentLocale) }}</small>
         </div>
          <div>
            <small><b>{{$t('message.color')}}:</b> {{ item.color ? item.color.name : "-" }}</small>
         </div>
         <base-checkbox :value="value" @input="handleCheckedChange"></base-checkbox>
      </div>
    </template>
  </stats-card>
</template>
<script>
import { mapGetters } from "vuex";
import DocumentImage from '@/components/DocumentImage'
import BhbStatusBadge from '@/components/BHBStatusBadge'
import isCustomerAdr from '@/util/customerAdr'

export default {
  name: 'GridItem',
  components: {
    BhbStatusBadge,
    DocumentImage
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    },
    imageSrc: String,
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'project',
      'currentLocale',
      'currentUserRights'
    ]),
    model: {
      get() {
        return this.item.isChecked;
      },
      set(check) {
        this.$emit("input", check);
      }
    }
  },
  data() {
    return {
      selectedPositions: []
    }
  },
  methods: {
      onClick (id) {
      const url = this.$router.resolve({ path: `/project/${this.$route.params.id}/bhb/${id}`})
      window.open(url.href, '_blank')
    },
    isCustomerAdr,
    handleCheckedChange (e) {
      const checked = !this.value
      this.$emit('input', checked)
    }
  },
  mounted() {

  }
};
</script>
<style scoped>
.col-md-6 {
  flex-grow: 1;
  transition: all 400ms ease;
}

.col-md-4 {
  width: 0;
  flex-shrink: 1;
  transition: all 400ms ease;
}

.cropped-img-container {
    height: 13vw;
    overflow: hidden;
}

.cropped-img-container .cropped-img {
  background: none center center/cover no-repeat transparent;
  width:100%;
  height: 100%;
}

@media(max-width:1199px) {
  .cropped-img-container {
      height: 25vw;
  }
}
@media(max-width:575px) {
  .cropped-img-container {
      height: 50vh;
  }
}
</style>
