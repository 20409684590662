<template>
  <card bodyClasses="p-0 d-flex align-items-stretch">
    <dl class="field">
      <dt>
        <template v-if="
          (position.priority_internal &&
            currentUserRights.includes(
              'im.details.priority.cansee-intern'
            )) ||
          position.priority_internal == false
        ">
          <el-tooltip placement="right" v-if="position.priority">
            <div slot="content">
              {{ position.priority_date | datetime }}:
              {{ position.priority_reason }}
            </div>
            <i class="fas fa-bolt active-star mr-2"></i>
          </el-tooltip>
        </template>
        {{ $t("message.area") }}
      </dt>
      <dd>
        {{ position.area ? position.area.name : "" }}
        <el-tooltip class="item" content="10.07.2019" effect="dark" placement="top-start" v-if="position.isPrioritised">
          <i class="fas fa-bolt faa-flash animated animated active-star"></i>
        </el-tooltip>
      </dd>
    </dl>
    <dl class="field">
      <dt>{{ $t("message.position") }}</dt>
      <dd>
        {{ position.bhb_position ? position.bhb_position.name : "" }}
      </dd>
    </dl>
    <dl class="field">
      <dd>
        {{ position.color ? position.color.name : "" }}
      </dd>
    </dl>
    <dl class="field">
      <dt>{{ $t("global.order") }}</dt>
      <dd>
        {{ position.order ? position.order.No : "" }}
      </dd>
    </dl>

    <dl class="field fill" v-if="currentUserRights.includes('BHB - Vollbild_Ansicht mit Preis')">
      <dt v-if="isCustomerAdr">{{ $t("message.price") }}</dt>
      <dd v-if="isCustomerAdr">{{ position.price_total | currency }}</dd>
    </dl>

    <div class="commands" v-loading="isLoading">
      <base-button size="sm" :type="position.rel_business ? 'success' : 'light'" @click="handleCostApprovalClick" v-if="
        currentUserRights.includes(
          'bhb.status.can-modify-wirtschaftliche-freigabe'
        )
      " :disabled="
  project.state === 'ARCHIVED' ||
  (currentUserRights.includes('bhb.status.can-modify-state-once') &&
    position.rel_business)
">
        {{ $t("message.economic_release") }}
        <i class="ni ni-check-bold" v-if="position.rel_business"></i>
        <small v-if="position.rel_business">
          {{
            position.user_rel_business ? position.user_rel_business.name : "?"
          }}
          - {{ (position.date_rel_business + " GMT") | datetime }}
        </small>
      </base-button>

      <base-button size="sm" :type="isApproved ? 'success' : 'light'" :disabled="
        isApproved ||
        project.state === 'ARCHIVED' ||
        (currentUserRights.includes('bhb.status.can-modify-state-once') &&
          nonResettableStates.includes(position.state_bhb))
      " @click="handleDesignApprovalClick" v-if="currentUserRights.includes('bhb.status.can-modify-freigabe')">
        {{ $t("message.release") }}
        <i class="ni ni-check-bold" v-if="isApproved"></i>
        <small v-if="isApproved">
          {{ position.user_rel_content ? position.user_rel_content.name : "?" }}
          - {{ (position.date_rel_content + " GMT") | datetime }}
        </small>
      </base-button>

      <base-button size="sm" :type="isCorrected ? 'warning' : 'light'" :disabled="
        isCorrected ||
        project.state === 'ARCHIVED' ||
        (currentUserRights.includes('bhb.status.can-modify-state-once') &&
          nonResettableStates.includes(position.state_bhb))
      " @click="handleCorrectionClick" v-if="currentUserRights.includes('bhb.status.can-modify-korrektur')">
        {{ $t("message.correction") }}
        <i class="ni ni-check-bold" v-if="isCorrected"></i>
      </base-button>
      <base-button size="sm" :type="isActionPL ? 'warning' : 'light'" @click="handleActionPL"
        v-if="currentUserRights.includes('bhb.status.can-modify-handlung-pl')" :disabled="project.state === 'ARCHIVED'">
        {{ $t("message.actionPL") }}
        <i class="ni ni-check-bold" v-if="isActionPL"></i>
      </base-button>
      <base-button size="sm" :type="isInProduction ? 'danger' : 'light'"
        :disabled="isInProduction || project.state === 'ARCHIVED'" @click="handleInProductionClick"
        v-if="currentUserRights.includes('bhb.status.can-modify-in-produktion')">
        {{ $t("message.inProduction") }}
        <i class="ni ni-check-bold" v-if="isInProduction"></i>
      </base-button>
      <base-button size="sm" :type="isOpen ? 'danger' : 'light'" :disabled="
        isOpen ||
        project.state === 'ARCHIVED' ||
        (currentUserRights.includes('bhb.status.can-modify-state-once') &&
          nonResettableStates.includes(position.state_bhb))
      " @click="handleOpenClick" v-if="currentUserRights.includes('bhb.status.can-modify-offen')">
        {{ $t("message.stateOpen") }}
        <i class="ni ni-check-bold" v-if="isOpen"></i>
      </base-button>
      <base-button size="sm" :type="isDeclined ? 'danger' : 'light'" :disabled="
        isDeclined ||
        project.state === 'ARCHIVED' ||
        (currentUserRights.includes('bhb.status.can-modify-state-once') &&
          nonResettableStates.includes(position.state_bhb)) ||
        position.rel_business
      " @click="handleDeclineClick" v-if="currentUserRights.includes('bhb.status.can-modify-ablehnen')">
        {{ $t("message.reject") }}
        <i class="ni ni-check-bold" v-if="isDeclined"></i>
        <span v-if="isDeclined">
          {{ position.user_rejected ? position.user_rejected.name : "?" }}
          - {{ (position.date_rejected + " GMT") | datetime }}
        </span>
      </base-button>
      <!--Added mounted and DV-created from InfoCard-->
      <base-button size="sm" type="info" @click="handleMountedClick"
        v-if="currentUserRights.includes('bhb.status.can-modify-montiert')">
        {{ $t(`message.mounted`) }}
        <i class="ni ni-check-bold" v-if="isMounted"></i>
      </base-button>
      <base-button size="sm" type="info" @click="handleDvErstelltClick"
        v-if="currentUserRights.includes('bhb.status.can-modify-dv-erstellt')">
        {{ $t(`message.dv-erstellt`) }}
        <i class="ni ni-check-bold" v-if="isDvErstellt"></i>
      </base-button>
    </div>

    <price-detail-modal :show.sync="showPriceDetailModal" :position="position" />
    <correction-modal :position="position" :show.sync="showCorrectionModal" @created="handleCorrectionSent" />
    <mounted-modal :position="position" :show.sync="showMountedModal" @created="handleMountedSent" />
  </card>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

import CorrectionModal from "./Correction";
import MountedModal from "./Mounted.vue";
import PriceDetailModal from "./PriceDetail";
import sleep from "@/util/sleep";
import isCustomerAdr from "@/util/customerAdr";
import BhbStatusBadge from "@/components/BHBStatusBadge";

export default {
  name: "position-header",
  props: {
    position: Object,
  },
  components: {
    CorrectionModal,
    MountedModal,
    PriceDetailModal,
    BhbStatusBadge,
  },
  data() {
    return {
      isLoading: false,
      showPriceDetailModal: false,
      showCorrectionModal: false,
      showMountedModal: false,
      nonResettableStates: ["FREIGEGEBEN", "KORREKTUR", "ABGELEHNT"],
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentUserRights", "project"]),
    isApproved() {
      return this.position.state_bhb === "FREIGEGEBEN";
    },
    isMounted() {
      return this.position.mounted == true;
    },
    isDvErstellt() {
      return this.position.dv_erstellt == true;
    },
    isCorrected() {
      return this.position.state_bhb === "KORREKTUR";
    },
    isDeclined() {
      return this.position.state_bhb === "ABGELEHNT";
    },
    isInProduction() {
      return this.position.state_bhb === "IN_PRODUKTION";
    },
    isOpen() {
      return this.position.state_bhb === "OFFEN";
    },
    isActionPL() {
      return this.position.handlung_pl;
    },
    isCustomerAdr() {
      return isCustomerAdr(
        this.currentUser,
        this.project,
        this.position.address
      );
    },
  },
  methods: {
    ...mapActions([
      "setBhbState",
      "setBhbActionPL",
      "setBhbMountedState",
      "setBhbMountedOk",
      "setBhbDvErstelltState",
      "toggleWirtschaftlicheFreigabe",
    ]),
    async confirm({ callback, confirmMessage, successMessage }) {
      try {
        await this.$confirm(
          this.$t(confirmMessage),
          this.$t("message.warning"),
          {
            confirmButtonText: this.$t("message.yes"),
            cancelButtonText: this.$t("message.no"),
            type: "warning",
          }
        );
      } catch (error) {
        return this.$message({
          type: "info",
          message: "Canceled",
        });
      }

      this.isLoading = true;
      try {
        await callback();
      } catch (error) {
        this.isLoading = false;
        return;
      }
      await sleep(100);

      this.isLoading = false;
      this.$emit("changed");
      if (successMessage) {
        this.$message({ type: "success", message: successMessage });
      }
    },
    handleCorrectionClick() {
      if (this.isApproved && this.currentUser.role_id == 13) {
        return;
      }
      this.showCorrectionModal = true;
    },
    handleMountedClick() {
      if (this.position.mounted === 0) {
        this.showMountedModal = true;
      } else {
        this.handleMountedSent();
      }
    },
    async handleCorrectionSent() {
      this.showCorrectionModal = false;
      await this.setBhbState({
        posId: this.position.id,
        bhbState: "KORREKTUR",
      });

      this.$emit("changed");
      this.$message({
        type: "success",
        message: this.$t("message.korrektur_success"),
      });
    },

    handleCostApprovalClick() {
      if (this.position.rel_business && this.currentUser.role_id == 13) {
        return;
      }
      this.confirm({
        callback: async () => {
          try {
            await this.toggleWirtschaftlicheFreigabe({
              posId: this.position.id,
              position: this.position,
            });
          } catch (error) {
            this.$message({
              type: "error",
              message: error.body ? error.body.message : error,
            });
            throw error;
          }
        },
        confirmMessage: this.position.rel_business
          ? "message.confirm_wirtschaftliche_freigabe_off"
          : "message.confirm_wirtschaftliche_freigabe_on",
        successMessage: this.position.rel_business
          ? this.$t("message.wirtschaftliche_freigabe_removed")
          : this.$t("message.wirtschaftliche_freigabe_success"),
      });
    },
    handleDeclineClick() {
      if (this.isApproved && this.currentUser.role_id == 13) {
        return;
      }
      this.confirm({
        callback: async () => {
          await this.setBhbState({
            posId: this.position.id,
            bhbState: "ABGELEHNT",
          });

          if (this.project.pt_sync_enabled && this.position.pt_id) {
            this.updatePT(this.position.pt_id, true)
          }
          
        },
        confirmMessage: "message.confirm_ablehnen",
      });
    },
    handleDesignApprovalClick() {
      this.confirm({
        callback: async () => {
          const bhbState =
            this.position.state_bhb === "FREIGEGEBEN" ? "OFFEN" : "FREIGEGEBEN";

          await this.setBhbState({ posId: this.position.id, bhbState });
        },
        confirmMessage: "message.confirm_freigabe",
        successMessage: this.$t("message.status_changed_success"),
      });
    },
    handleInProductionClick() {
      this.confirm({
        callback: async () => {
          const bhbState =
            this.position.state_bhb === "IN_PRODUKTION"
              ? "FREIGEGEBEN"
              : "IN_PRODUKTION";

          await this.setBhbState({ posId: this.position.id, bhbState });
        },
        confirmMessage: "message.confirm_produktion",
        successMessage: this.$t("message.status_changed_success"),
      });
    },
    async handleOpenClick() {
      this.confirm({
        callback: async () => {
          const bhbState = "OFFEN";
          await this.setBhbState({ posId: this.position.id, bhbState });
        },
        confirmMessage: "message.confirm_open",
        successMessage: this.$t("message.status_changed_success"),
      });
    },
    handleActionPL() {
      const actionPL = !this.position.handlung_pl;
      this.confirm({
        callback: async () => {
          await this.setBhbActionPL({ posId: this.position.id, actionPL });
        },
        confirmMessage: actionPL
          ? "message.confirm_action_pl"
          : "message.confirm_no_action_pl",
        successMessage: this.$t("message.status_changed_success"),
      });
    },
    handlePriceClick() {
      this.showPriceDetailModal = true;
    },
    async handleMountedSent(params) {
      this.showMountedModal = false;
      const mountedState = !this.position.mounted;
      this.position.mounted = mountedState;
      await this.setBhbMountedState({ posId: this.position.id, mountedState });
      if (params) {
        await this.setBhbMountedOk({ posId: this.position.id, isOk: params.isOk })
      }
      this.$emit("changed");
    },
    async handleDvErstelltClick() {
      const dvErstelltState = !this.position.dv_erstellt;
      await this.setBhbDvErstelltState({
        posId: this.position.id,
        dvErstelltState,
      });
      this.$emit("changed");
    },
    async updatePT(posID, reject) {
      let rejected = reject ? '-INAKTIV' : '';
      try {
        await Vue.http.get('pt/sync/' + posID + rejected);
        this.$notify({
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Position wurde in PT archiviert.',
          type: 'success'
        })
      } catch (response) {
        this.$notify({
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Fehler bei Übertragung an PT: <br>' + response.body.message,
          type: 'error'
        });
      }
    },
  },
  watch: {
    isApproved: function () {
      console.log("changed");
    },
  },
};
</script>

<style scoped>
.field,
.commands {
  padding: .8em;
}

.field {
  display: flex;
  margin: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  line-height: 1.8em;
  font-size: 0.95em;
}

.field.fill {
  flex: 1;
  border-left: none;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.field>dt {
  margin: 0 5px 0 0;
}

.field>dd {
  margin: 0;
}
</style>
