<template>
  <modal @update:show="onShowChange" :show="show" class="price-list-modal" :bodyClasses="'dropdown-overflow-correction'">
    <h6 slot="header" class="modal-title">{{ $t("message.correction") }}</h6>
    <apa-comments
      hideComments
      placeholder="Was soll korrigiert werden?"
      @created="onCreated"
      :fixtures="{
        project_id: position.project_id,
        position_id: position.id,
        type: 'KORR_COMMENT'
      }"
    />
    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onClose">{{
        $t("message.cancel")
      }}</base-button>
    </template>
  </modal>
</template>
<script>
import { mapGetters } from "vuex";

import ApaComments from "@/components/ApaComments/ApaComments";

export default {
  name: "CorrectionModal",
  components: {
    ApaComments,
  },
  props: {
    position: {
      type: Object,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.onShowChange(false);
    },
    onCreated() {
      this.$emit("created");
    },
    onShowChange(state) {
      this.$emit("update:show", state);
    },
  },
};
</script>
<style>
.dropdown-overflow-correction{
  overflow-y: inherit !important;
}
</style>
