<template>
  <div class="container-fluid" v-if="position">
    <page-header :position="position" v-on:changed="handleChanged" />
    <position-header :position="position" v-on:changed="handleChanged" />
    <images-card :position="position"  v-on:changed="handleChanged"/>
    <info-card :position="position" v-on:changed="handleChanged"/>
    <comments-card :position="position" v-on:stateChanged="handleChangedState" :title="$t('message.comments')"/>
    <archive-card :position="position" v-if="currentUserRights.includes('project.archive.isvisible')"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from '@/store'

import ArchiveCard from "./ArchiveCard";
import CommentsCard from "./CommentsCard";
import ImagesCard from "./ImagesCard";
import InfoCard from "./InfoCard";
import PageHeader from "./PageHeader";
import PositionHeader from "./PositionHeader";

export default {
  name: "detail-view",
  components: {
    ArchiveCard,
    CommentsCard,
    ImagesCard,
    InfoCard,
    PageHeader,
    PositionHeader
  },
  props: {},
  data() {
    return {
      positionId: -1
    };
  },
  computed: {
    ...mapGetters(["position", 'currentUserRights'])
  },
  watch: {
    $route(val) {
      this.load();
    }
  },
  methods: {
    ...mapActions(["getBhbPosition"]),
    handleChanged() {
      this.load(true);
    },
    handleChangedState(newState) {
      this.position.state_bhb = newState;
    },
    load(force = false) {
      const positionId = parseInt(this.$route.params.posId);
      if (positionId !== this.positionId || force) {
        this.positionId = positionId;
        this.getBhbPosition(positionId);
      }
    }
  },
  beforeRouteLeave (to, from , next) {
    store.commit('position', null);
    next()
  },
  mounted() {
    this.load();
  }
};
</script>
